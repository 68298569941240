/* google-fonts.css */
@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
  max-width: 100vw;
  overflow-x: hidden;
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  max-width: 100vw;
  overflow-x: hidden;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Monda";
  src: local("Monda"), url(./assets/fonts/Monda-Regular.ttf);
  font-display: swap;
}
/* 
@font-face {
  font-family: "NeueMachina";
  src: url(./assets/fonts/NeueMachina-Ultralight.otf);
  font-weight: 100;
}

@font-face {
  font-family: "NeueMachina";
  src: url(./assets/fonts/NeueMachina-Light.otf);
  font-weight: 300;
}

@font-face {
  font-family: "NeueMachina";
  src: url(./assets/fonts/NeueMachina-Regular.otf);
  font-weight: 400;
}

@font-face {
  font-family: "NeueMachina";
  src: url(./assets/fonts/NeueMachina-Medium.otf);
  font-weight: 500;
}

@font-face {
  font-family: "NeueMachina";
  src: url(./assets/fonts/NeueMachina-Bold.otf);
  font-weight: 600;
}

@font-face {
  font-family: "NeueMachina";
  src: url(./assets/fonts/NeueMachina-Ultrabold.otf);
  font-weight: 800;
}

@font-face {
  font-family: "NeueMachina";
  src: url(./assets/fonts/NeueMachina-Black.otf);
  font-weight: 900;
} */

@font-face {
  font-family: "NeueMachinaBackup";
  src: local("NeueMachinaBackup"),
    url("https://daziu008xh8wa.cloudfront.net/assets/NeueMachina-Regular.otf")
      format("truetype");
  font-weight: 300, 400, 500, 600, 700, 800;
  font-display: swap;
}

a {
  text-decoration: none !important;
}

/* swiper */

.swiper {
  z-index: 0 !important;
}

.slider-sub .swiper-wrapper {
  width: inherit;
}

.slider-sub .swiper-wrapper {
  justify-content: center;
  column-gap: 1.6rem;
}

.slider-sub .swiper-slide.swiper-slide-visible {
  width: 4rem !important;
  margin: 0 !important;
  cursor: pointer;
}

@media (min-width: 300px) {
  .slider-sub .swiper-slide.swiper-slide-visible {
    width: 6rem !important;
  }
}

@media (min-width: 820px) {
  .slider-sub .swiper-slide.swiper-slide-visible {
    width: 10rem !important;
  }
}

.click-chat-bot {
  bottom: 14rem !important;
  right: 2.4rem !important;
  /* height: 50rem !important; */
  background-color: transparent !important;
  z-index: 0 !important;
}

.container-chat-bot {
  /* height: 50rem !important; */
}

.iframe-chat-bot {
  height: 50rem !important;
  right: 0 !important;
  bottom: 6rem !important;
  left: unset !important;
  width: 30rem !important;
}

.container-chat-bot .iframe-chat-bot .iframe {
  margin: 0 !important;
}

.click-chat-bot {
  bottom: 8rem !important;
}

@media (min-width: 820px) {
  .iframe-chat-bot {
    bottom: 7rem !important;
    width: 35rem !important;
  }

  .click-chat-bot {
    bottom: 16rem !important;
  }
}

@media (min-width: 1024px) {
  .click-chat-bot {
    bottom: 17rem !important;
    right: 3.2rem !important;
  }
  .icon-chat-bot {
    display: flex !important;
    justify-content: end !important;
    width: 100% !important;
  }

  #image-chat-bot {
    height: 4.8rem !important;
    width: auto !important;
    margin-left: auto !important;
  }
}

/* disable hide password icon default of browser */
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

#_purchase {
  /* display: none; */
  width: 30rem !important;
  @media (min-width: 820px) {
    width: 46rem !important;
  }
}

/* croll bar css */

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #d1d1d1;
  border-radius: 99999px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b43bf0;
  border-radius: 99999px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #bd53f1;
  transition: linear 300ms;
}
